<template>
  <view-item title="营业统计报表">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="营业收入" name="customer">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="customer.table[customer.mode]" :model="customer.form.data">
                    <en-form-item label="日期" v-if="customer.mode === 'daily'">
                      <en-date-picker
                        v-model:start="customer.form.data.startDate"
                        v-model:end="customer.form.data.endDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="日期" v-if="customer.mode === 'monthly'">
                      <en-date-picker
                        v-model:start="customer.form.data.startMonth"
                        v-model:end="customer.form.data.endMonth"
                        type="monthrange"
                        value-format="YYYY-MM"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="统计类型">
                  <en-checkbox v-model="customer.mode" true-label="daily" false-label="daily">按日统计</en-checkbox>
                  <en-checkbox v-model="customer.mode" true-label="monthly" false-label="monthly">按月统计</en-checkbox>
                </en-form-item>
                  
                  </form-query>

                  <flex-box v-if="customer.mode === 'daily'">
                    <template #default="{ height }">
                      <table-dynamic
                        code="INCSTMT"
                        :height="height"
                        :data="customer.table.data"
                        :loading="customer.table.loading"
                        :paging="customer.table.paging"
                        :summary="customer.table.summary.data"
                        :method="customer.table.daily"
                        pagination
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                  <flex-box v-if="customer.mode === 'monthly'">
                    <template #default="{ height }">
                      <table-dynamic
                        code="INCSTMT"
                        :height="height"
                        :data="customer.table.data"
                        :loading="customer.table.loading"
                        :paging="customer.table.paging"
                        :summary="customer.table.summary.data"
                        :method="customer.table.monthly"
                        pagination
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="采销分析" name="purchasesale">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="purchasesale.table.monthly">
                    <en-form-item label="日期">
                      <en-date-picker
                        v-model:start="purchasesale.form.data.startMonth"
                        v-model:end="purchasesale.form.data.endMonth"
                        type="monthrange"
                        value-format="YYYY-MM"
                      ></en-date-picker>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="PUCSALANLS"
                        :height="height"
                        :data="purchasesale.table.data"
                        :loading="purchasesale.table.loading"
                        :summary-method="purchasesale.table.summaryMethod"
                        :method="purchasesale.table[purchasesale.form.data.mode]"
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="套餐卡券" name="coupon">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="coupon.table.daily">
                    <en-form-item label="日期">
                      <en-date-picker
                        v-model:start="coupon.form.data.couponInstanceStartDate"
                        v-model:end="coupon.form.data.couponInstanceEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="MALCPSMFD"
                        :height="height"
                        :data="coupon.table.data"
                        :loading="coupon.table.loading"
                        :paging="coupon.table.paging"
                        :summary-method="coupon.table.summaryMethod"
                        :method="coupon.table[coupon.form.data.mode]"
                        pagination
                        show-summary
                        :config="coupon.table.config"
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'
import { calculator } from '@enocloud/utils'
import { type TableColumnCtx } from '@enocloud/components'
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              purchasesale: {
                action: 'GET /enocloud/settlement/statistics/purchasesale/export',
                params(params) {
                  params.payload = omit(this.purchasesale.form.data, 'mode')
                }
              },
              income: {
                action: 'GET /enocloud/statement/:timeWindow/income/export',
                params(params) {
                  params.paths = ['monthly']
                  params.payload = omit(this.customer.form.data, 'mode')
                }
              },
              coupon: {
                action: 'GET /enocloud/mall/coupon/summary/export',
                params(params) {
                  params.payload = omit(this.coupon.form.data, 'mode')
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'purchasesale':
                  this.operation.export.purchasesale()
                  this.store.openDownload()
                  break
                case 'customer':
                  this.operation.export.income()
                  this.store.openDownload()
                  break
                case 'coupon':
                  this.operation.export.coupon()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'customer'
      },
      customer: {
        mode: 'daily' as 'daily' | 'monthly',
        children: {
          form: {
            data: {
              branchIds: [],
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD'),
              startMonth: dayjs().format('YYYY-MM'),
              endMonth: dayjs().format('YYYY-MM')
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/:timeWindow/income',
                summary: 'GET /enocloud/statement/:timeWindow/income/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.customer.form.data, 'mode', 'branchIds', 'endDate', 'startDate')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/:timeWindow/income',
                summary: 'GET /enocloud/statement/:timeWindow/income/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.customer.form.data, 'mode', 'branchIds', 'startMonth', 'endMonth')
                }
              }
            },
            summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
              columns: TableColumnCtx<T>[]
              data: T[]
            }) {
              return ctx.columns
                .map((item) => item.property)
                .map((key, index) => {
                  if (
                    ['count','maintenanceAmount','workingTeamBonusAmount','goodsAmount','managementAmount','tax','goodsCost','otherFeeAmount','discountAmountOfMaintenance','discountAmountOfGoods','autoEraseAmount','discountAmount','serviceReceivableAmount','badDebt','receiptAmount','debt'].includes(key)
                  ) {
                    return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                  } else return !index ? '合计' : '-'
                })
            }
          }
        }
      },
      purchasesale: {
        children: {
          form: {
            is: 'form',
            data: {
              mode: 'daily',
              endMonth: dayjs().format('YYYY-MM'),
              startMonth: dayjs().format('YYYY-MM')
            }
          },
          table: {
            ajax: {
              monthly: {
                action: 'GET /enocloud/settlement/statistics/purchasesale',
                summary: 'GET /enocloud/settlement/statistics/purchasesale/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.purchasesale.form.data, 'endMonth', 'startMonth', 'mode')
                }
              }
            },
            summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
              columns: TableColumnCtx<T>[]
              data: T[]
            }) {
              return ctx.columns
                .map((item) => item.property)
                .map((key, index) => {
                  if (
                    ['purchaseAmount', 'purchaseReturnAmount', 'goodsAmountBeforeDiscount', 'goodsAmount', 'goodsCost', 'goodsProfit'].includes(key)
                  ) {
                    return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                  } else return !index ? '合计' : '-'
                })
            }
          }
        }
      },
      coupon: {
        children: {
          form: {
            is: 'form',
            data: {
              mode: 'daily',
              couponInstanceStartDate: '',
              couponInstanceEndDate: '',
              sortedBy: 'TOTAL_COUNT_desc'
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/mall/coupon/summary',
                summary: 'GET /enocloud/mall/coupon/summary/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.coupon.form.data, 'couponInstanceStartDate', 'couponInstanceEndDate', 'mode', 'sortedBy')
                }
              }
            },
            summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
              columns: TableColumnCtx<T>[]
              data: T[]
            }) {
              return ctx.columns
                .map((item) => item.property)
                .map((key, index) => {
                  if (['disabledCount', 'expiredCount', 'checkedCount', 'leftCount', 'totalCount'].includes(key)) {
                    return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                  } else return !index ? '合计' : '-'
                })
            },
            config:{
              NAME: {
          header: {
            filter: {
              type: 'text',
              field: 'name'
            }
          }
        }, SERIAL_NO: {
          header: {
            filter: {
              type: 'text',
              field: 'serialNo'
            }
          }
        },
        
        TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'type',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['MALCPTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
            }
          }
        }
      }
    }
  },

  async mounted() {

    this.customer.table.daily()

  }
})
</script>
